
var body = $('body');

$(function () {
	moment.locale('en', {
	  week: { dow: 1 } // Monday is the first day of the week
	});

    $('.datetimepicker').datetimepicker({
    	format: 'DD.MM.YYYY'
    });

    body.on('click', '.clear-search', function(){
    	$('.search-row input, .search-row select').val('');
    })

    body.on('change', '#clients', function(){
        var usersSelect = $('#users');

        emptyElem = $( 'option:first-child', usersSelect);
        usersSelect.empty().append($('<option></option>').attr('value', emptyElem.val()).text(emptyElem.text()));

        $.getJSON('/users/list_user/' + $(this).val(), function(data) {
            $.each(data, function(key, value) {
		        $('<option>').val(key).text(value).appendTo(usersSelect);
		    });
        });
    })
});
